'use client';
import { useState, useEffect, useContext } from 'react';
import { fetchUserData, updateDailyCheckIn } from '../utils/firestoreHelper';
import { PenguinsContext } from '../context/PenguinsContext';
import { useRouter } from 'next/navigation';

function DailyReward({ userId, onClose }) {
  const { penguins, setPenguins } = useContext(PenguinsContext);
  const [checkInDay, setCheckInDay] = useState(1);
  const [showCheckIn, setShowCheckIn] = useState(true);
  const router = useRouter();

useEffect(() => {
  const handleDailyReward = async () => {
    try {
      console.log("Fetching user data...");
      const userData = await fetchUserData(userId);

      // If user data is invalid, log it
      if (!userData || !userData.lastCheckInDate) {
        console.error("User data is null or lastCheckInDate is missing");
        return;
      }

      const today = new Date().toISOString().split('T')[0];
      const lastCheckInLocal = localStorage.getItem('lastCheckInDate');

      // If the last check-in in localStorage matches today's date, don't show the popup
      if (lastCheckInLocal === today) {
        console.log("User has already claimed the reward today.");
        return;
      }

      // If the user has not checked in today
      if (userData.lastCheckInDate !== today) {
        let newCheckInCount = userData.checkInCount + 1;

        // If more than a day has passed since last check-in, reset the count
        if (new Date(userData.lastCheckInDate) < new Date(new Date().setDate(new Date().getDate() - 1))) {
          newCheckInCount = 1;
        }

        const rewardDay = Math.min(newCheckInCount, 10); // Cap the reward day at 10
        const rewardPenguins = rewardDay * 10;
        const newPenguinsBalance = penguins + rewardPenguins;

        // Update penguins in context and Firestore
        setPenguins(newPenguinsBalance);
        await updateDailyCheckIn(userId, newCheckInCount, newPenguinsBalance, today); // Pass today's date to Firestore

        // Store today's date in localStorage to prevent multiple popups
        localStorage.setItem('lastCheckInDate', today);

        // Update local states
        setCheckInDay(newCheckInCount);
        setShowCheckIn(true);
        console.log("Initial screen: showCheckIn = true");

        // Switch to the second part (rewards) after 3 seconds
        setTimeout(() => {
          setShowCheckIn(false);
          console.log("Switched to reward screen: showCheckIn = false");
        }, 3000);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  handleDailyReward();

  // Disable scrolling when the popup is visible
  document.body.style.overflow = 'hidden';

  return () => {
    document.body.style.overflow = 'auto'; // Re-enable scrolling on cleanup
  };
}, [userId, penguins, setPenguins]);

  const handleContinue = () => {
    router.push('/'); // Navigate to homepage
  };

  return (
    <div className="fixed inset-0 flex flex-col justify-center items-center bg-black text-white z-50">
      {showCheckIn ? (
        // First part: Show Check-in day
        <div className="text-6xl font-bold text-center">
          {checkInDay} day check-in
          <p className="text-sm mt-4">
            Check-ins are your best friend here. Keep them going for bonuses that are straight 🔥
          </p>
        </div>
      ) : (
        // Second part: Show rewards
        <div className="text-center">
          <img src="/path-to-your-lightning-icon.png" alt="Reward Icon" className="mb-6 mx-auto" />
          <div className="text-4xl font-bold mb-4">Your daily rewards</div>
          <div className="flex justify-center space-x-8 mb-6">
            <div>
              <img src="/path-to-your-penguin-icon.png" alt="$PENGUINS" className="mb-2" />
              <div className="text-3xl font-bold">{checkInDay * 10}</div>
              <div>$PENGUINS</div>
            </div>
            <div>
              <img src="/path-to-your-ticket-icon.png" alt="Spin Tickets" className="mb-2" />
              <div className="text-3xl font-bold">{checkInDay}</div>
              <div>Spin Tickets</div>
            </div>
          </div>
          <button
            className="bg-blue-600 px-4 py-2 text-lg rounded-md z-[9999]"
            onClick={() => {
            console.log('Button clicked'); // Check if the button click is being captured
            onClose();
          }}
          >
            Continue
          </button>
          <p className="mt-4 text-sm">
            Tip: Skipping a day resets your check-in progress.
          </p>
        </div>
      )}
    </div>
  );
}

export default DailyReward;
