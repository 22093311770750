'use client';

import { useTonConnectUI, useTonWallet, useTonAddress } from '@tonconnect/ui-react'; // Import necessary hooks
import { RiWallet3Fill } from 'react-icons/ri';
import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/navigation';
import { useTelegram } from './hooks/useTelegram';
import { PenguinsContext } from './context/PenguinsContext'; // Import the Penguins context
import DailyReward from './components/dailyreward';

// Firestore imports
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase/firebase'; // Adjust path to your firebase initialization

export default function Home() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const router = useRouter();
  const { user } = useTelegram();
  const [tonConnectUI] = useTonConnectUI(); // Hook to interact with TON Connect UI
  const wallet = useTonWallet(); // Hook to get wallet info if connected
  const userFriendlyAddress = useTonAddress(); // Hook to get the user-friendly wallet address
  const { penguins } = useContext(PenguinsContext); // Get penguins state from context
  

    useEffect(() => {
  const sendUserDataToFirestore = async (user) => {
    try {
      const userId = String(user?.id); // Convert user ID to string
  
      if (!userId || typeof userId !== 'string') {
        console.error('Invalid user ID:', userId);
        return;
      }
  
      const userDocRef = doc(db, 'users', userId); 
      const userDocSnapshot = await getDoc(userDocRef);
  
      const userData = {
        fullName: `${user.first_name} ${user.last_name}`,
        username: user.username,
        penguins: `${penguins}`, // Use penguins state from context
      };
  
      if (userDocSnapshot.exists()) {
        await updateDoc(userDocRef, {
          ...userData,
          timeUpdated: serverTimestamp(),
        });
        console.log('User document updated successfully');
      } else {
        await setDoc(userDocRef, {
          ...userData,
          timeCreated: serverTimestamp(),
          timeUpdated: serverTimestamp(),
        });
        console.log('User document created successfully');
      }
    } catch (error) {
      console.error('Error sending user data to Firestore:', error);
    }
  };

    if (user) {
      sendUserDataToFirestore(user); // Trigger Firestore logic when the app loads and the user is available
    }
  }, [user, penguins]);


  const handleScroll = (event) => {
    const scrollLeft = event.target.scrollLeft;
    const cardWidth = event.target.firstChild.clientWidth;
    const index = Math.round(scrollLeft / cardWidth);
    setActiveIndex(index);
  };

  const handleCheckScoreClick = () => {
    router.push('/metrics'); // Redirect to the metrics page
  };

  const unlockRewards = () => {
    router.push('/tasks'); // Redirect to the tasks page
  };

 const joinCommunity = () => {
  if (typeof window !== 'undefined') {
    // This will run only on the client
    window.location.href = 'https://t.me/thepenguinscommunity'; // Redirect after wallet connection
  }
};

  // Function to handle wallet connection/disconnection
  const handleWalletButtonClick = async () => {
    if (wallet) {
      // Wallet is already connected, show the popup
      setPopupVisible(true);
    } else {
      // Wallet is not connected, initiate wallet connection
      try {
        await tonConnectUI.connectWallet(); // Initiate wallet connection
      } catch (error) {
        console.error('Failed to connect wallet', error);
      }
    }
  };

  const handlePopupAction = async (action) => {
    try {
      if (action === 'disconnect') {
        await tonConnectUI.disconnect(); // Disconnect wallet
      } else if (action === 'changeAddress') {
        await tonConnectUI.disconnect(); // Disconnect wallet
        await tonConnectUI.openModal(); // Reconnect to change address
      }
      setPopupVisible(false); // Hide popup after action
    } catch (error) {
      console.error(`Failed to ${action} wallet`, error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <h1 className="mt-6 text-2xl font-bold">{user ? `${user.first_name} ${user.last_name}` : 'Fetching Data...'}</h1>
      <button 
        onClick={handleWalletButtonClick}
        className="mt-8 bg-white text-black font-bold py-2 px-4 rounded-2xl flex items-center transform scale-80 w-50"
      >
        <RiWallet3Fill className="mr-2" />
        {wallet && userFriendlyAddress
          ? `${userFriendlyAddress.slice(0, 6)}...${userFriendlyAddress.slice(-4)}` // Show part of the wallet address if connected
          : 'Connect Wallet'}  {/* Show 'Connect Wallet' if not connected */}
      </button>

      {/* Popup for wallet actions */}
      {popupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-lg flex flex-col items-center">
            <button
              onClick={() => handlePopupAction('changeAddress')}
              className="bg-white text-black font-bold py-2 px-4 rounded-2xl mb-2"
            >
              Change Address
            </button>
            <button
              onClick={() => handlePopupAction('disconnect')}
              className="bg-white text-black font-bold py-2 px-4 rounded-2xl"
            >
              Disconnect
            </button>
            <button
              onClick={() => setPopupVisible(false)}
              className="mt-2 text-gray-600 underline"
            >
              Close
            </button>
          </div>
        </div>
      )}

      <p className="text-5xl mt-4 font-bold">{penguins}</p>
      <p className="text-2xl mt-1">$PENGUINS</p>

       {/* Swipable Cards Section */}
       <div className="mt-12 w-full overflow-x-scroll flex space-x-4 p-4 snap-x snap-mandatory hide-scrollbar"
         onScroll={handleScroll}
       >
        <div
          className="min-w-[360px] rounded-2xl p-4 flex-shrink-0 text-left snap-center"
          style={{ backgroundColor: "#111111", color: "#ffffff" }} // Using color codes
        >
          <h2 className="text-xl font-semibold">Share your Progress</h2>
          <p className="text-sm text-gray-400 mt-1">In Telegram stories</p>
          <button className="mt-4 bg-white text-black py-2 px-6 rounded-full font-semibold">
            Share
          </button>
        </div>
        <div
          className="min-w-[360px] rounded-2xl p-4 flex-shrink-0 text-left snap-center"
          style={{ backgroundColor: "#111111", color: "#ffffff" }} // Using color codes
        >
          <h2 className="text-xl font-semibold">Unlock Rewards</h2>
          <p className="text-sm text-gray-400 mt-1">Complete tasks to earn</p>
          <button className="mt-4 bg-white text-black py-2 px-6 rounded-full font-semibold"
          onClick={unlockRewards}
          >
            Unlock
          </button>
        </div>
        <div
          className="min-w-[360px] rounded-2xl p-4 flex-shrink-0 text-left snap-center"
          style={{ backgroundColor: "#111111", color: "#ffffff" }} // Using color codes
        >
          <h2 className="text-xl font-semibold">Join the Community</h2>
          <p className="text-sm text-gray-400 mt-1">Connect with others</p>
          <button className="mt-4 bg-white text-black py-2 px-6 rounded-full font-semibold"
          onClick={joinCommunity}
          >
            Join
          </button>
        </div>
      </div>

      {/* Dots indicator */}
      <div className="flex space-x-1 mt-4">
        <div
          className={`w-2 h-2 ${
            activeIndex === 0
              ? "bg-white"
              : activeIndex === 1
              ? "bg-gray-600"
              : "bg-gray-800"
          } rounded-full`}
        ></div>
        <div
          className={`w-2 h-2 ${
            activeIndex === 1
              ? "bg-white"
              : activeIndex === 2
              ? "bg-gray-600"
              : "bg-gray-800"
          } rounded-full`}
        ></div>
        <div
          className={`w-2 h-2 ${
            activeIndex === 2
              ? "bg-white"
              : activeIndex === 1
              ? "bg-gray-600"
              : "bg-gray-800"
          } rounded-full`}
        ></div>
      </div>
      
      {/* Rewards Section */}
      <div className="mt-8 w-full max-w-md mx-auto text-left text-white px-4">
        <h2 className="text-xl font-bold mb-4">Your rewards</h2>
        <ul className="space-y-4">
          {[
            { icon: "check", label: "Daily Check In", value: "+1,500 $PENGUINS" },
            { icon: "star", label: "Account age", value: "+1,000 $PENGUINS" },
            { icon: "premium", label: "Telegram Premium", value: "0 $PENGUINS" },
            { icon: "friends", label: "Invited folks", value: "+750 $PENGUINS" },
          ].map((reward, index) => (
            <li key={index} className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="bg-gray-700 p-2 rounded-full">
                  <svg
                    className="w-6 h-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </div>
                <span className="ml-4">{reward.label}</span>
              </div>
              <span className="text-gray-400">{reward.value}</span>
            </li>
          ))}
        </ul>
      </div> 

      {/* Check Score */}
      <div className="px-6 mb-20" onClick={handleCheckScoreClick}>
        <div className="mt-8 px-5 py-5 w-full max-w-md mx-auto bg-gray-900 rounded-2xl overflow-hidden relative">
          <img src='/images/checkscore.png' alt="PENGUINS got DOG'S back" className="w-full h-auto rounded-2xl" />

          {/* Text overlay with blur effect */}
          <div className="absolute bottom-0 left-0 right-0 p-4 rounded-b-2xl backdrop-blur-3"
            style={{
              background: 'linear-gradient(to bottom, rgba(31, 41, 55, 0), rgba(31, 41, 55, 0), rgba(31, 41, 55, 0.05))'
            }}>
            <p className="text-lg font-bold text-white">View Score</p>
            <p className="text-sm font-semibold text-white">Review your metric and score!</p>
          </div>
        </div>
      </div>
    </div>
  );
}
