'use client';
import { createContext, useState, useEffect } from 'react';

export const PenguinsContext = createContext();

export const PenguinsProvider = ({ children }) => {
  // Penguins and Spin Tickets State
  const [penguins, setPenguins] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedPenguins = localStorage.getItem('penguins');
      return storedPenguins ? Number(storedPenguins) : 1000;
    }
    return 1000;
  });

  const [spinTickets, setSpinTickets] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedTickets = localStorage.getItem('spinTickets');
      return storedTickets ? Number(storedTickets) : 0;
    }
    return 0;
  });

  // Daily Streak State
  const [dailyStreak, setDailyStreak] = useState(() => {
    if (typeof window !== 'undefined') {
      const storedStreak = localStorage.getItem('dailyStreak');
      return storedStreak ? Number(storedStreak) : 1;
    }
    return 1;
  });

  const [lastClaimDate, setLastClaimDate] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lastClaimDate') || null;
    }
    return null;
  });

  // Save changes to localStorage whenever values change
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('penguins', penguins);
      localStorage.setItem('spinTickets', spinTickets);
      localStorage.setItem('dailyStreak', dailyStreak);
      localStorage.setItem('lastClaimDate', lastClaimDate);
    }
  }, [penguins, spinTickets, dailyStreak, lastClaimDate]);

  // Function to claim the daily reward
  const claimDailyReward = () => {
    const today = new Date().toDateString();
    if (lastClaimDate === today) {
      alert('You have already claimed today’s reward.');
      return;
    }

    // Calculate reward based on daily streak
    const penguinsReward = dailyStreak * 100;
    const ticketReward = dailyStreak;

    // Update penguins and spin tickets
    setPenguins(penguins + penguinsReward);
    setSpinTickets(spinTickets + ticketReward);

    // Update streak and last claim date
    const newStreak = dailyStreak >= 10 ? 1 : dailyStreak + 1;
    setDailyStreak(newStreak);
    setLastClaimDate(today);
  };

  return (
    <PenguinsContext.Provider
      value={{ penguins, setPenguins, spinTickets, setSpinTickets, dailyStreak, claimDailyReward }}
    >
      {children}
    </PenguinsContext.Provider>
  );
};
