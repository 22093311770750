'use client';

import { useEffect, useState } from 'react';

export function useTelegram() {
    const [user, setUser] = useState(null);
  
    useEffect(() => {
        // Initialize Telegram Web Apps API
        if (typeof window !== 'undefined' && window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;

          // Initializes the Web App
          tg.ready();

          tg.setHeaderColor('#000000'); // Replace '#FF5733' with your desired color

           // Debugging: Log initialization
            console.log('Telegram Web App API Initialized:', tg);

            // Attempt to get user information
            const userInfo = tg.initDataUnsafe?.user;

            // Debugging: Log the user information
            console.log('User Info:', userInfo);

            setUser(userInfo);
    
          // Optional: Set Web App appearance or other settings here
          tg.expand(); // Expands the web app to full height
    
          // Function to enable the closing confirmation
          function enableClosingConfirmation() {
            tg.isClosingConfirmationEnabled = true;
        }
    
        function disableVerticalSwipes() {
            tg.disableVerticalSwipes();
        }
    
        enableClosingConfirmation();
        disableVerticalSwipes();
    
          // Optionally handle closing the Web App
          tg.onEvent('close', () => {
            console.log('WebApp closed');
          });
        }
      }, []);
  
    return { user };
  }